<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      <p class="mb-0">
        Copyright {{ today | date: 'y' }} © BYD Energy | Platform Version
        {{ version }} | API Version {{ apiVersion$ | async }}
      </p>
    </div>
  </div>
</div>
