{
  "name": "energy",
  "version": "2.4.177",
  "scripts": {
    "ng": "ng",
    "start": "node --max_old_space_size=4096 ./node_modules/@angular/cli/bin/ng serve",
    "build": "ng build",
    "build:staging": "node --max_old_space_size=4096 ./node_modules/@angular/cli/bin/ng build",
    "test": "ng test",
    "e2e": "ng e2e",
    "build:deploy:ecs": "npm run build -- --configuration=production && rsync -vr dist/cuba/* /var/www/html/app.bydenergia.com",
    "build:deploy:staging": "npm run build:staging -- --configuration=staging && rsync -vr dist/cuba/* /var/www/html/app.bydenergia.com",
    "hykem": "node src/scripts/hykem-translator.js",
    "lint:prettier:check": "prettier --check .",
    "lint:prettier:fix": "prettier --write ."
  },
  "private": true,
  "dependencies": {
    "@amcharts/amcharts4": "^4.10.39",
    "@amcharts/amcharts5": "^5.10.7",
    "@angular-slider/ngx-slider": "^18.0.0",
    "@angular/animations": "^18.1.0",
    "@angular/cdk": "^18.2.12",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/forms": "^18.1.0",
    "@angular/material": "^18.2.12",
    "@angular/material-moment-adapter": "^18.2.12",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.1.0",
    "@angular/router": "^18.1.0",
    "@capacitor/core": "^6.1.2",
    "@capacitor/device": "^6.0.1",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@iplab/ngx-color-picker": "^18.0.1",
    "@ks89/angular-modal-gallery": "^12.0.0",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "@ngx-loading-bar/core": "^7.0.0",
    "@ngx-loading-bar/http-client": "^7.0.0",
    "@ngx-loading-bar/router": "^7.0.0",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@ngxs/devtools-plugin": "^18.1.4",
    "@ngxs/logger-plugin": "^18.1.4",
    "@ngxs/schematics": "^0.0.1-alpha.5",
    "@ngxs/store": "^18.1.4",
    "@popperjs/core": "^2.11.8",
    "@sweetalert2/ngx-sweetalert2": "^12.4.0",
    "@swimlane/ngx-datatable": "^20.1.0",
    "angular-feather": "^6.5.1",
    "animate.css": "^4.1.1",
    "apexcharts": "^3.54.1",
    "bootstrap": "^5.3.2",
    "buffer": "^6.0.3",
    "cpf-cnpj-validator": "^1.0.3",
    "event-source-polyfill": "^1.0.31",
    "exceljs": "^4.4.0",
    "feather-icons": "^4.29.2",
    "file-saver": "^2.0.5",
    "firebase": "^11.0.1",
    "font-awesome": "^4.7.0",
    "leaflet": "^1.8.0",
    "moment": "^2.30.1",
    "ng-apexcharts": "^1.12.0",
    "ng2-pdf-viewer": "^10.3.4",
    "ng5-slider": "^1.2.6",
    "ngx-countup": "^13.2.0",
    "ngx-dropzone": "^3.1.0",
    "ngx-dropzone-wrapper": "^17.0.0",
    "ngx-mask": "^18.0.3",
    "rxjs": "~7.8.0",
    "socket.io-client": "^4.8.1",
    "stream-browserify": "^3.0.0",
    "sweetalert2": "^11.14.5",
    "swiper": "^8.4.5",
    "tslib": "^2.3.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.1",
    "@angular/cli": "^18.1.1",
    "@angular/compiler-cli": "^18.1.0",
    "@angular/localize": "^18.1.0",
    "@types/event-source-polyfill": "^1.0.5",
    "@types/feather-icons": "^4.29.4",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.0",
    "@types/leaflet": "^1.9.14",
    "@types/node": "^22.9.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.3.3",
    "swiper": "^8.4.5",
    "typescript": "~5.5.2"
  }
}
