import { Component, inject } from '@angular/core'
import packageInfo from '../../../../../package.json'
import { LayoutMetaState } from 'src/store/layout/layout.meta.state'
import { Store } from '@ngxs/store'
import { AsyncPipe, DatePipe } from '@angular/common'

@Component({
  standalone: true,
  imports: [DatePipe, AsyncPipe],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  private store = inject(Store)
  public today: number = Date.now()
  public version = packageInfo.version

  apiVersion$ = this.store.select(LayoutMetaState.getApiVersion)
}
